.trait {
  height: 300px;
  border: solid 2px white;
}
/* src/Components/Footer/Footer.css */
footer {
  font-family: Arial, sans-serif;
}

footer .policy {
  margin-top: 16px;
  text-align: center;
}

footer .border-t {
  border-top: 1px solid #4a4a4a;
}

footer a {
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

.fb {
  color: white;
}
.fb:hover {
  color: #60a5fa;
}
